// IMPORTS
@import (reference) "../settings.less";

.main-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 64px;
}

// MAIN GREETING
.main-greeting {
    display: flex;
    text-align: center;
    grid-column: 1 / -1;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 16px;
    padding: 44px 0;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        z-index: -1;
        background-color: #1e0d04;
    }

    .headline {
        margin-bottom: 0;
    }
}

// MAIN BANNER
.main-banner {
    width: 100%;
    margin: 0;

    .swiper-slide {
        display: block;
        aspect-ratio: 936 / 380;
        position: relative;
    }

    .banner-img-wrapper {
        position: absolute;
        inset: 0;
    }

    img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    b {
        color: #fefefe;
        font-family: @sec-font-family;
        font-weight: normal;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 36px;
        background-color: rgba(73,42,32,.9);
        width: fit-content;
        text-align: center;
        padding: 13px 20px 17px;
        min-width: 401px;
        font-size: 24px;
    }
}

// MAIN PAGE PRODUCTS
.main-page-products-wrapper {
    position: relative;

    .swiper-button-prev {
        left: -20px;
    }

    .swiper-button-next {
        right: -20px;
    }
}

// BANNERS GRID
.banners-grid {
    position: relative;
    aspect-ratio: 936 / 423;

    &:empty {
        display: none;
    }

    a {
        position: absolute;

        &:hover {
            
            img {
                transform: scale(.98);
                box-shadow: 0 0 15px rgba(255,255,255,.3);
            }
        }

        &.one {
            top: 0;
            left: 0;
            width: 215px;
            aspect-ratio: 215 / 216;

            span {
                bottom: 19px;
                left: 19px;
                max-width: ~"calc(100% - 38px)";
            }
        }

        &.two {
            top: 0;
            left: 241px;
            width: 215px;
            aspect-ratio: 215 / 216;

            span {
                bottom: 19px;
                left: 19px;
                max-width: ~"calc(100% - 38px)";
            }
        }

        &.three {
            top: 0;
            right: 0;
            width: 453px;
            aspect-ratio: 453 / 138;

            span {
                bottom: 19px;
                left: 19px;
                max-width: ~"calc(100% - 38px)";
                color: #2e1204;
            }
        }

        &.four {
            bottom: 0;
            left: 0;
            width: 456px;
            aspect-ratio: 456 / 181;

            span {
                bottom: 28px;
                right: 28px;
                max-width: ~"calc(100% - 38px)";
                font-size: 30px;
                line-height: 30px;
            }
        }

        &.five {
            bottom: 0;
            right: 0;
            width: 453px;
            aspect-ratio: 453 / 259;

            span {
                bottom: 28px;
                right: 28px;
                max-width: ~"calc(100% - 38px)";
                color: #2e1204;
                font-size: 30px;
                line-height: 30px;
            }
        }
    }

    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }

    span {
        position: absolute;
        font-family: @sec-font-family;
        font-size: 24px;
        line-height: 24px;
        color: white;

        b {
            display: block;
        }
    }
}